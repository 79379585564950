<template>
<div>
<van-empty image="network" description="支付跳转中..." v-if="loading" />
</div>
</template>

<script>
import { Empty } from 'vant';
import { setToken } from "@/utils/auth";
import { wapPay } from "@/api/unipay";

export default {
 data() {
     return {
       loading: true,
      // 请求参数
      submitParams: {
         appId: this.$route.query && this.$route.query.appId,
         orderId: this.$route.query && this.$route.query.orderId,
         txnAmt: this.$route.query && this.$route.query.txnAmt,
         currencyCode: this.$route.query && this.$route.query.currencyCode,
         orderDesc: this.$route.query && this.$route.query.orderDesc,
         frontUrl: this.$route.query && this.$route.query.frontUrl,
         backUrl: '',
         payTimeout: this.$route.query && this.$route.query.payTimeout
      }
     };
   },
  components: {
    [Empty.name]: Empty
  },
  created() {
     this.onInit();
     this.onSubmit();
  },
  methods: {
      // 初始化
      onInit() {
        if(this.submitParams.appId === undefined) {
           return this.$router.push({name:'pay-error',query:{ respCode: 500, respMsg: 'appId未提供'}});
        }
        if(this.submitParams.orderId === undefined) {
           return this.$router.push({name:'pay-error',query:{ respCode: 500, respMsg: '订单号未提供'}});
        }
        if(this.submitParams.txnAmt === undefined) {
           return this.$router.push({name:'pay-error',query:{ respCode: 500, respMsg: '金额未提供'}});
        }
        if(this.submitParams.currencyCode === undefined) {
           this.submitParams.currencyCode = '156';
        }
        setToken(this.submitParams.appId);
      },
      // 手机支付
      onSubmit() {
        this.loading = false;
        wapPay(this.submitParams).then(response => {
            if(response.code == 200 && response.data.html != undefined) {
                 return document.write(response.data.html);
            } else {
                 return this.$router.push({name:'pay-error',query:{ respCode: response.code, respMsg: response.msg}});
            }
        });
      }
  }
};

</script>
