import request from '@/utils/request'

// 手机支付
export function wapPay(data) {
    console.log(data);
    const submitParams = {
        orderId: data.orderId === undefined ? '' : data.orderId,
        txnAmt: data.txnAmt === undefined ? '' : data.txnAmt,
        currencyCode: data.currencyCode === undefined ? '156' : data.currencyCode,
        orderDesc: data.orderDesc === undefined ? '' : data.orderDesc,
        frontUrl: data.frontUrl === undefined ? '' : data.frontUrl,
        backUrl: data.backUrl === undefined ? '' : data.backUrl,
        payTimeout: data.payTimeout === undefined ? '' : data.payTimeout
      };

    return request({
        url: 'open/union/1.0/wapPay',
        method: 'post',
        data: submitParams
      })
}
